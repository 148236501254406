


















































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class DeviceTypes extends Vue {
  pagerHeader: any = {
    title: "型号管理",
    desc: "型号管理",
    breadcrumb: ["设备管理", "型号管理"]
  };
  loading: boolean = false;
  filter: any = {
    page: 1,
    size: 10,
    keywords: ""
  };
  dateRange: any = [];
  tableData: any = {
    items: [],
    totalCount: 0
  };
  merchantItems: any[] = [];
  merchantSearchLoading: boolean = false;
//   @Watch("dateRange")
//   dateRangeChnage(value: Date[]) {
//     if (value != null && value.length == 2) {
//       this.filter.startDate = value[0].Format("yyyy-MM-dd hh:mm:ss");
//       this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss");
//     } else {
//       this.filter.startDate = "";
//       this.filter.endDate = "";
//     }
//   }
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.readerPage();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.readerPage();
  }
  async readerPage() {
    this.loading = true;
    try {
      let res = await this.$ajax.get(
        "/api/services/app/DeviceType/GetPagedList",
        {
          params: {
            ...this.filter
          }
        }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  search() {
    this.filter.page = 1;
    // this.filter.keywords = this.keywords;
    this.readerPage();
  }
  activated() {
    this.readerPage();
  }
  created() {
    this.filter.size = this.systemSettings.pageSize;
    this.readerPage();
  }
  async cancelOrder(id: string) {
    var result = await this.$confirm("确定要取消该商品吗?");
    if (result == "confirm") {
      let loading = this.$loading({
        text: "取消中..."
      });
      try {
        var res = await this.$ajax.post(
          `/api/services/app/ChargingOrder/CanneOrder?orderId=${id}`
        );
        if (res.data.success) {
          this.$message.success("取消成功");
          this.readerPage();
        }
      } catch (error) {}
      loading.close();
    }
  }
  async deleteDeviceType(row: any) {
    var result = await this.$confirm(`确认要删除设备【${row.name}】吗？`);
    if (result == "confirm") {
      var loading = this.$loading({
        text: "删除中..."
      });
      try {
        var res = await this.$ajax.delete(
          "/api/services/app/DeviceType/Delete",
          {
            params: {
              id: row.id
            }
          }
        );
        if (res.data.success) {
          this.$message.success("删除成功");
          this.readerPage();
        }
      } catch (error) {}
      loading.close();
    }
  }
}
